import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "import-icons"
    }}>{`Import Icons`}</h1>
    <p>{`We have a large list of pre-built `}{`[app icons]`}{`, `}{`[product icons]`}{`, and `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/nJm6wc7Hxb1bDevfr5ZgKM/PLATFORM-PROTOTYPES?type=design&node-id=11470-63390&mode=design&t=nhBUu57TQFP1eTIT-0"
      }}>{`dataset icons`}</a>{` in figma. This script will help you export them and turn them into a single svg with symbols. This makes it more efficient for the client to load and use.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <ol>
      <li parentName="ol">{`Export the new svg icons from figma`}</li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`node scripts/import-dataset-icons path/to/the/figma/icons`}</inlineCode></li>
      <li parentName="ol">{`Address any errors that come up in the terminal`}</li>
      <li parentName="ol">{`Files are written to `}<inlineCode parentName="li">{`public/`}</inlineCode>{`. Rename them according to your needs.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      